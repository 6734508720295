import React, { Component } from 'react';
import { Label } from "../../components/ui/label";
import { Switch } from "../../components/ui/switch";
import { cn } from "../../lib/utils";
import { Checkbox } from "../../components/ui/checkbox";
import { Navigate, json } from "react-router-dom";
import config from "../../utils/config.js";
import toast from 'react-hot-toast';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../components/ui/select";
import { Link } from 'react-router-dom';

class PreferencesForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            servings: 1,
            diet: {
                "No Preference": true,
                "Vegetarian": false,
                "Kosher": false,
                "Vegan": false,
                "Ketogenic (Keto)": false,
                "Paleolithic (Paleo)": false,
                "Dairy Free": false,
                "Gluten Free": false,
                "Halal": false,
                "Pescatarian": false,
            },
            allergies: {
                "Milk (Lactose)": false,
                "Eggs": false,
                "Fish": false,
                "Shellfish": false,
                "Peanuts": false,
                "Tree nuts": false,
                "Sesame": false,
                "Soy": false,
                "Wheat / Gluten": false
            },
            languages: {
                "English": true,
                "Spanish": false,
                "French": false,
                "Italian": false,
                "German": false,
                "Hindi": false,
                "Mandarin": false,
                "Japanese": false
            },
            measurement: {
                "Metric": false,
                "Imperial": false
            },
            skill: {
                "Beginner": false,
                "Intermediate": false,
                "Advanced": false
            },
            appliances: {
                "Conventional Oven": false,
                "Convection Oven": false,
                "Air Fryer": false,
                "Microwave Oven": false,
                "Toaster Oven": false,
                "Pizza Oven": false,
                "Pressure Cooker": false,
                "Slow Cooker": false,
                "Multicooker": false,
                "Sous Vide": false,
                "Food Processor": false,
                "Blender": false,
                "Immersion Blender": false,
            },
            showError: false,
            temperature: false,
            language: "english",
            units: false,
            cuisine: "american",
            userSelected: { diet: 'No Preference' }
        };
    }
 
    componentDidMount() {
        const savedState = localStorage.getItem('preferencesState');
        if (savedState) {
            this.setState(JSON.parse(savedState));
        }
    }

    updateLocalStorage() {
        localStorage.setItem('preferencesState', JSON.stringify(this.state));
    }

    handleStateChange(key, updatingState) {
        this.setState(prevState => {
            const updatedState = {};
            for (const dietKey in prevState[updatingState]) {
                updatedState[dietKey] = (dietKey === key && !prevState[updatingState][dietKey]);
            }
            return { [updatingState]: updatedState };
        }, this.updateLocalStorage);
    }

    handleMultiStateChange(key, updatingState, value) {
        this.setState(prevState => {
            const updatedState = { ...prevState[updatingState] };
            updatedState[key] = !updatedState[key];
            
            // Update userSelected with the new state of appliances or other categories
            const newUserSelected = { ...prevState.userSelected };
            newUserSelected[updatingState] = updatedState;
    
            return { 
                [updatingState]: updatedState,
                userSelected: newUserSelected
            };
        }, this.updateLocalStorage);
    }

    handleInputValue(e) {
        const value = Number(e.target.value);
        this.setState({
            servings: value
        })
        if (value <= 0) {
            this.setState({
                showError: true
            });
        } else {
            this.setState({
                showError: false
            }, () => {
                localStorage.setItem('number_of_people', value.toString());
                this.updateLocalStorage();
            });
        }
    }

    async savePreferences() {
        if(this.props.istryNow) {
            this.props.onSubmit()
            this.setState({
                loading: true
            })
        }
        if (localStorage.getItem('number_of_people') && Number(localStorage.getItem('number_of_people')) <= 0) {
            this.setState({
                showError: true
            });
            alert('Please fill all the fields correctly.');
        } else {
            const selectedDiet = this.state.userSelected.diet || "no";
            const selectedAppliances = this.state.userSelected.appliances || {};
            const selectedAllergies = this.state.userSelected.allergies || {};
            const selectedLanguages = this.state.languages || {};
            console.log(this.state.userSelected.languages, this.state.languages)
            const selectedMeasurement = this.state.userSelected.measurement || {};
            const selectedSkill = this.state.userSelected.skill || {};
    
            const trueAppliances = Object.keys(selectedAppliances).filter(key => selectedAppliances[key]);
            const trueAllergies = Object.keys(selectedAllergies).filter(key => selectedAllergies[key]);
            const finalLanguage = Object.keys(selectedLanguages).filter(key => selectedLanguages[key] === true);
            const trueMeasurement = Object.keys(selectedMeasurement).find(key => selectedMeasurement[key]) || "metric";
            const trueSkill = Object.keys(selectedSkill).find(key => selectedSkill[key]) || "beginner";
    
          
           setTimeout(async () => {
            const url = `${config.API_SERVER_URL}/api/v1/store-user-appliance`;
            const authToken = localStorage.getItem('userToken');
            const headers = {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            };
            const dietMapping =  {
                "no-preference": "no",
                "vegetarian": "vegetarian",
                "kosher": "kosher",
                "vegan": "vegan",
                "ketogenic-(keto)": "keto",
                "paleolithic-(paleo)": "paleo",
                "dairy-free": "dairy-free",
                "gluten-free": "gluten-free",
                "halal": "halal",
                "pescatarian": "pescatarian"
            }
           const selectedDietValue = dietMapping[selectedDiet] || "no";
console.log(finalLanguage, "finalLanguage")
           const data = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                appliance: {
                    appliances: trueAppliances.map(key => key.replace(/ /g, '-').toLowerCase()),
                    diet: selectedDietValue,
                    servings: this.state.servings ? this.state.servings : 0,
                    allergies: trueAllergies.map(key => key.replace(/ /g, '-').toLowerCase()),
                    language: finalLanguage[0],
                    measurement: trueMeasurement.toLowerCase(),
                    skill_level: trueSkill.toLowerCase()
                }
            })
        });
        const response = await data.json();
        if (response.status !== 200 && response.status !== 201) {
            console.log("response is", response);
        };
           }, 2500);
           
            if(!this.props.istryNow) {

            const svgElement = document.getElementsByClassName('lucide')[0];
            if (svgElement) {
                const event = new MouseEvent('click', {
                    view: window,
                    bubbles: true,
                    cancelable: true
                });
                svgElement.dispatchEvent(event);
            } else {
                console.log('SVG element not found');
            }
        }
            toast("Preferences saved successfully!", {
                position: 'top-right'
            });
            this.updateLocalStorage();
            if(!this.props.istryNow) {
                document.getElementById('clicktoevent').click();
            }

        }
      
    }
    

    render() {
        return (
            <div className="">
                <div className="mb-6">
                    <div className="text-2xl text-black font-bold mb-5">Personalization Preferences</div>
                    <div className='text-xl text-black font-bold'>Diet</div>
                    <div className=''>
                        {Object.entries(this.state.diet).map(([key, value]) => {
                            return <button
                                onClick={() => this.handleStateChange(key, "diet")}
                                className={value ? 'mr-3 mt-3 px-[12px] py-[3px] bg-[#0e4621] font-bold rounded-[32px] text-white' : ' mr-3 mt-3 px-[12px] py-[3px] bg-gray-200 rounded-[32px] opacity-65 hover:opacity-100 text-gray-500 font-bold border-2 border-black-500 hover:shadow-sm hover:text-white hover:bg-[#0e4621] text-black'}
                            >{key}</button>
                        })}
                    </div>
                </div>
                <div className="mb-6">
                    <div className='text-xl text-black font-bold'>Allergies and Restrictions</div>
                    <div className=''>
                        {Object.entries(this.state.allergies).map(([key, value]) => {
                            return <button
                                onClick={() => this.handleMultiStateChange(key, "allergies", value)}
                                className={value ? 'mr-3 mt-3 px-[12px] py-[3px] bg-[#0e4621] font-bold rounded-[32px] text-white' : ' mr-3 mt-3 px-[12px] py-[3px] bg-gray-200 rounded-[32px] opacity-65 hover:opacity-100 text-gray-500 font-bold border-2 border-black-500 hover:shadow-sm hover:text-white hover:bg-[#0e4621] text-black'}
                            >{key}</button>
                        })}
                    </div>
                </div>
                <div className="mb-6">
                    <div className='text-xl text-black font-bold'>Number of people I typically cook for</div>
                    <div className='mt-3'>
                        <input min="1" defaultValue={Number(localStorage.getItem('number_of_people'))} type="number" onKeyUp={(e) => this.handleInputValue(e)} onBlur={(e) => this.handleInputValue(e)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[180px] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="1" required />
                    </div>
                    {this.state.showError && <div className='bg-red-600 text-white p-3 mt-2'>Number of people should be at least 1 or above</div>}
                </div>
                <div className="mb-6">
                    <div className='text-xl text-black font-bold'>Language Preferences</div>
                    <div className=''>
                        {Object.entries(this.state.languages).map(([key, value]) => {
                            return <button
                                onClick={() => this.handleStateChange(key, "languages")}
                                className={value ? 'mr-3 mt-3 px-[12px] py-[3px] bg-[#0e4621] font-bold rounded-[32px] text-white' : ' mr-3 mt-3 px-[12px] py-[3px] bg-gray-200 rounded-[32px] opacity-65 hover:opacity-100 text-gray-500 font-bold border-2 border-black-500 hover:shadow-sm hover:text-white hover:bg-[#0e4621] text-black'}
                            >{key}</button>
                        })}
                    </div>
                </div>
                <div className="mb-6">
                    <div className='text-xl text-black font-bold'>Units of Measurement</div>
                    <div className=''>
                        {Object.entries(this.state.measurement).map(([key, value]) => {
                            return <button
                                onClick={() => this.handleStateChange(key, "measurement")}
                                className={value ? 'mr-3 mt-3 px-[12px] py-[3px] bg-[#0e4621] font-bold rounded-[32px] text-white' : ' mr-3 mt-3 px-[12px] py-[3px] bg-gray-200 rounded-[32px] opacity-65 hover:opacity-100 text-gray-500 font-bold border-2 border-black-500 hover:shadow-sm hover:text-white hover:bg-[#0e4621] text-black'}
                            >{key}</button>
                        })}
                    </div>
                </div>
                <div className="mb-6">
                    <div className='text-xl text-black font-bold'>Skill Level</div>
                    <div className=''>
                        {Object.entries(this.state.skill).map(([key, value]) => {
                            return <button
                                onClick={() => this.handleStateChange(key, "skill")}
                                className={value ? 'mr-3 mt-3 px-[12px] py-[3px] bg-[#0e4621] font-bold rounded-[32px] text-white' : ' mr-3 mt-3 px-[12px] py-[3px] bg-gray-200 rounded-[32px] opacity-65 hover:opacity-100 text-gray-500 font-bold border-2 border-black-500 hover:shadow-sm hover:text-white hover:bg-[#0e4621] text-black'}
                            >{key}</button>
                        })}
                    </div>
                </div>
                <div className="mb-6">
                    <div className='text-xl text-black font-bold'>Kitchen Appliances I regularly use</div>
                    <div className=''>
                        {Object.entries(this.state.appliances).map(([key, value]) => {
                            return <button
                                onClick={() => this.handleMultiStateChange(key, "appliances", value)}
                                className={value ? 'mr-3 mt-3 px-[12px] py-[3px] bg-[#0e4621] font-bold rounded-[32px] text-white' : ' mr-3 mt-3 px-[12px] py-[3px] bg-gray-200 rounded-[32px] opacity-65 hover:opacity-100 text-gray-500 font-bold border-2 border-black-500 hover:shadow-sm hover:text-white hover:bg-[#0e4621] text-black'}
                            >{key}</button>
                        })}
                    </div>
                </div>
                <Link style={{
                    display: 'none'
                }} to="/" id="clicktoevent">Go to Target</Link>

                {this.props.istryNow ?   <div className="mb-4 text-center">
                    <button disabled={this.state.loading} onClick={this.savePreferences.bind(this)}  className="green">
                        {this.state.loading ? `Loading...`
                         : "Submit"}
                    </button>
                </div>: 
                <div className="mb-4 text-center">
                    <button onClick={this.savePreferences.bind(this)} className="newgreen">
                        Save
                    </button>

                </div>}
            </div >
        );
    }
}

export default PreferencesForm;
